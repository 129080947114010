import React, { useState } from "react";
import { EuiSideNav, htmlIdGenerator } from "@equipmentshare/ds2";

export default function SideNav() {
	const [isSideNavOpenOnMobile, setIsSideNavOpenOnMobile] = useState(false);

	const toggleOpenOnMobile = () => {
		setIsSideNavOpenOnMobile(!isSideNavOpenOnMobile);
	};

	return (
		<EuiSideNav
			mobileTitle="Menu"
			toggleOpenOnMobile={toggleOpenOnMobile}
			isOpenOnMobile={isSideNavOpenOnMobile}
			items={[
				{
					name: "Menu",
					id: htmlIdGenerator("Menu")(),
					items: [
						//TODO: Should be replaced with actual router links
						{
							name: "Home",
							id: htmlIdGenerator("Home")(),
							isSelected: true,
							href: "/",
						},
						{
							name: "Identities",
							id: htmlIdGenerator("Identities")(),
							isSelected: true,
							href: "/identities",
						},
					],
				},
			]}
		/>
	);
}
