import {
	createContext,
	HTMLAttributes,
	ReactChild,
	ReactNode,
	useContext,
	useState,
} from "react";
import { EuiGlobalToastList, IconType } from "@equipmentshare/ds2";

type ToastProps = {
	id: string;
	text?: ReactChild;
	toastLifeTimeMs?: number;
	title?: ReactNode;
	color?: "primary" | "success" | "warning" | "danger";
	iconType?: IconType;
	onClose?: () => void;
} & Omit<HTMLAttributes<HTMLDivElement>, "title">;

export type ToastStateContext = {
	toasts: ToastProps[];
	showToast: (toast: ToastProps) => void;
	removeToast: (toast: ToastProps) => void;
};

const ToastSTateContextDefaults = {
	toasts: [],
	showToast: () => {},
	removeToast: () => {},
};

export const toastStateContext = createContext<ToastStateContext>(
	ToastSTateContextDefaults
);

export default function ToastProvider({ children }: any) {
	const [toasts, setToasts] = useState<ToastProps[]>([]);

	const removeToast = (removedToast: ToastProps) => {
		setToasts((prevValue) =>
			prevValue.filter((toast: ToastProps) => toast.id !== removedToast.id)
		);
	};

	const showToast = (toast: ToastProps) => {
		setToasts((prevValue) => [...prevValue, toast]);
	};

	const contextValue = { toasts, showToast, removeToast };

	return (
		<toastStateContext.Provider value={contextValue}>
			{children}
			<EuiGlobalToastList
				toasts={toasts}
				dismissToast={removeToast}
				toastLifeTimeMs={10000}
			/>
		</toastStateContext.Provider>
	);
}

export const useToasts = () => useContext(toastStateContext);
