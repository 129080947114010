import { useQuery, UseQueryResult } from "react-query";
import { Auth0ContextInterface } from "@equipmentshare/auth0-react";
import makeAuthHeader from "../makeAuthHeader";
import { ESAPIUser } from "../../types/user";

export async function fetchUsers(
	auth0: Auth0ContextInterface,
	apiRoot: string,
	query: string | undefined
) {
	if (!query) {
		return undefined;
	}

	const headers = await makeAuthHeader(auth0);
	// TODO: update this fetch to be more in line with the other queries
	const usersResponse = await fetch(`${apiRoot}/admin/users?query=${query}`, {
		headers,
	})
		.then(async (res) => await res.json())
		.catch((reason) => {
			if (reason?.request?.status !== 404) {
				console.error(reason);
			}
			return undefined;
		});

	if (usersResponse === undefined) {
		return undefined;
	}

	return usersResponse;
}

export const useUsersQuery = (
	auth0: Auth0ContextInterface,
	query: string
): UseQueryResult<ESAPIUser[] | undefined> => {
	const apiRoot = process.env.REACT_APP_API_URL ?? "";

	return useQuery<ESAPIUser[] | undefined>(
		"get:useUsersQuery",
		() => fetchUsers(auth0, apiRoot, query),
		{ refetchOnWindowFocus: false }
	);
};
