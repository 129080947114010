import { useState } from "react";
import { useAuth0 } from "@equipmentshare/auth0-react";
import { useQueryClient } from "react-query";
import {
	EuiButton,
	EuiComboBoxOptionOption,
	EuiFlexGroup,
	EuiFlexItem,
	EuiLoadingSpinner,
	EuiSpacer,
} from "@equipmentshare/ds2";

import { ListIdentity } from "../../types/identity";
import UserSelector from "../userSelector/userSelector";
import { useAddUserGrantMutation } from "../../api/mutations/userGrants";
import { useToasts } from "../../utilities/toastProvider";

export type UserGrantQuickAddProps = {
	identity: ListIdentity;
};

export default function UserGrantQuickAdd({
	identity,
}: UserGrantQuickAddProps) {
	const auth0 = useAuth0();
	const queryClient = useQueryClient();
	const toasts = useToasts();
	const [selectedUser, setSelectedUser] = useState<
		EuiComboBoxOptionOption | undefined
	>();
	const addUserGrantMutation = useAddUserGrantMutation();

	const addUserGrant = () => {
		addUserGrantMutation.mutate(
			{
				identityId: identity.identity_id,
				userId: selectedUser?.key || "",
				auth0,
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(
						`get:useIdentityUserGrantsQuery:${identity.identity_id}`
					);
					toasts.showToast({
						id: new Date().getTime().toString(),
						color: "success",
						title: `Grant created`,
						text: (
							<p>
								{identity.email} : {selectedUser?.value}
							</p>
						),
					});
					setSelectedUser(undefined);
				},
				onError: () => {
					toasts.showToast({
						id: new Date().getTime().toString(),
						color: "danger",
						title: `Failed to create grant`,
						text: (
							<p>
								{identity.email} : {selectedUser?.value}
							</p>
						),
					});
				},
			}
		);
	};

	const cancelAddUserGrant = () => {
		setSelectedUser(undefined);
	};

	const confirmationDialog = (
		<EuiFlexGroup direction="column" alignItems="flexStart">
			<EuiFlexItem>
				Are you sure you would like to grant:
				<strong>{selectedUser?.label}</strong>
			</EuiFlexItem>
			<EuiFlexItem>
				to the following identity:<strong>{identity.email}</strong>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiButton color="primary" onClick={addUserGrant}>
							Add Grant
						</EuiButton>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton color="danger" onClick={cancelAddUserGrant}>
							Cancel
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlexItem>
		</EuiFlexGroup>
	);

	return (
		<>
			<UserSelector identity={identity} onSelect={setSelectedUser} />
			{selectedUser && !addUserGrantMutation.isLoading
				? confirmationDialog
				: null}
			{addUserGrantMutation.isLoading ? (
				<>
					<EuiSpacer />
					<EuiLoadingSpinner size="l" />
				</>
			) : null}
		</>
	);
}
