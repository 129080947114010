import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@equipmentshare/auth0-react";
import {
	EuiPageHeader,
	EuiFlexGroup,
	EuiFlexItem,
	EuiHorizontalRule,
	EuiTitle,
	EuiSpacer,
} from "@equipmentshare/ds2";

import GrantsTable from "../components/grantsTable/grantsTable";
import { useIdentityByIdentityId } from "../api/queries/useIdentityByIdentityId";
import { useToasts } from "../utilities/toastProvider";
import IdentityEditForm from "../components/identityEditForm/identityEditForm";

export default function IdentityView() {
	const params = useParams<any>();
	const auth0 = useAuth0();
	const toasts = useToasts();
	const {
		data: identity,
		isLoading,
		isError,
	} = useIdentityByIdentityId(auth0, params?.identityId);

	useEffect(() => {
		if (isError) {
			toasts.showToast({
				id: new Date().getTime().toString(),
				color: "danger",
				title: "Failed to fetch Identity",
			});
		}
	}, [isError]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<EuiPageHeader
				pageTitle="Manage Identity"
				iconType="user"
				rightSideItems={[]}
			/>

			<EuiHorizontalRule margin="xl" />

			<IdentityEditForm identity={identity} />

			<EuiHorizontalRule margin="xl" />

			<EuiFlexGroup>
				<EuiFlexItem>
					<EuiTitle>
						<h2>Active Grants</h2>
					</EuiTitle>
					<EuiSpacer />
					{!isLoading ? <GrantsTable identity={identity} /> : null}
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiTitle>
						<h2>Expired Grants</h2>
					</EuiTitle>
					<EuiSpacer />
					<EuiFlexGroup alignItems="center">
						<EuiFlexItem>Coming Soon</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</>
	);
}
