import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@equipmentshare/auth0-react";
import { DefaultReactQueryProvider } from "@equipmentshare/universal-header";
import "@equipmentshare/ds2/dist/assets/ds2.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ToastProvider from "./utilities/toastProvider";

ReactDOM.render(
	<React.StrictMode>
		<DefaultReactQueryProvider>
			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
				clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ""}
				redirectUri={window.location.origin}
				audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ""}
			>
				<BrowserRouter>
					<ToastProvider>
						<App />
					</ToastProvider>
				</BrowserRouter>
			</Auth0Provider>
		</DefaultReactQueryProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
