import { useQuery, UseQueryResult } from "react-query";
import { Auth0ContextInterface } from "@equipmentshare/auth0-react";
import { Identity } from "../../types/identity";
import makeAuthHeader from "../makeAuthHeader";

export async function fetchIdentityByIdentityId(
	auth0: Auth0ContextInterface,
	identityId: string | undefined
) {
	if (!identityId) {
		return undefined;
	}

	const headers = await makeAuthHeader(auth0);
	const identityResponse = await fetch(
		`${process.env.REACT_APP_API_URL}/admin/identities/view?identity_id=${identityId}`,
		{ headers }
	);

	if (!identityResponse.ok) {
		throw new Error(identityResponse.statusText);
	}

	return (await identityResponse.json()) as Identity;
}

export const useIdentityByIdentityId = (
	auth0: Auth0ContextInterface,
	identityId: string | undefined
): UseQueryResult<Identity | undefined> => {
	return useQuery<Identity | undefined>(
		`get:useIdentityByIdentityId:${identityId}`,
		() => fetchIdentityByIdentityId(auth0, identityId),
		{ refetchOnWindowFocus: false, enabled: Boolean(identityId) }
	);
};
