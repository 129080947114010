import { useMutation, UseMutationResult } from "react-query";
import { Auth0ContextInterface } from "@equipmentshare/auth0-react";

import makeAuthHeader from "../makeAuthHeader";

export async function addUserGrant(
	userGrantData: UserGrantParams
): Promise<void> {
	const headers = await makeAuthHeader(userGrantData.auth0);
	headers["Content-Type"] = "application/json";
	const requestBody = JSON.stringify({
		identity_id: userGrantData.identityId,
		user_id: userGrantData.userId,
	});
	await fetch(
		`${process.env.REACT_APP_API_URL}/admin/identities/user-grants/create`,
		{ headers, method: "POST", body: requestBody }
	);
}

export async function removeUserGrant(
	userGrantData: UserGrantParams
): Promise<void> {
	const headers = await makeAuthHeader(userGrantData.auth0);
	headers["Content-Type"] = "application/json";
	const requestBody = JSON.stringify({
		identity_id: userGrantData.identityId,
		user_id: userGrantData.userId,
	});
	await fetch(
		`${process.env.REACT_APP_API_URL}/admin/identities/user-grants/remove`,
		{
			headers,
			method: "POST",
			body: requestBody,
		}
	);
}

export async function setDefaultUserGrant(
	userGrantData: UserGrantParams
): Promise<void> {
	const headers = await makeAuthHeader(userGrantData.auth0);
	headers["Content-Type"] = "application/json";
	const requestBody = JSON.stringify({
		identity_id: userGrantData.identityId,
		user_id: userGrantData.userId,
	});
	await fetch(
		`${process.env.REACT_APP_API_URL}/admin/identities/user-grants/set-default`,
		{
			headers,
			method: "POST",
			body: requestBody,
		}
	);
}

export type UserGrantParams = {
	auth0: Auth0ContextInterface;
	identityId?: string;
	userId?: string;
};

export function useAddUserGrantMutation(): UseMutationResult<
	void,
	Error,
	UserGrantParams
> {
	return useMutation(
		["addUserGrant", "host", "accessToken"],
		async (userGrantData: UserGrantParams) => await addUserGrant(userGrantData)
	);
}

export function useRemoveUserGrantMutation(): UseMutationResult<
	void,
	Error,
	UserGrantParams
> {
	return useMutation(
		["removeUserGrant", "host", "accessToken"],
		async (userGrantData: UserGrantParams) =>
			await removeUserGrant(userGrantData)
	);
}

export function useSetDefaultUserGrantMutation(): UseMutationResult<
	void,
	Error,
	UserGrantParams
> {
	return useMutation(
		["setDefaultUserGrant", "host", "accessToken"],
		async (userGrantData: UserGrantParams) =>
			await setDefaultUserGrant(userGrantData)
	);
}
