import {
	Auth0ContextInterface,
	getIdentity,
} from "@equipmentshare/auth0-react";

export type RequestHeader = {
	authorization: string;
	"x-es-identity": string;
	"Content-Type"?: string;
};

const makeAuthHeader = async (
	auth0: Auth0ContextInterface
): Promise<RequestHeader> => {
	const { accessToken, idToken } = await getIdentity(auth0);

	return {
		authorization: `Bearer ${accessToken}`,
		"x-es-identity": idToken,
	} as const;
};

export default makeAuthHeader;
