import React, { useEffect, useState } from "react";
import { useAuth0 } from "@equipmentshare/auth0-react";
import { useQueryClient } from "react-query";
import {
	EuiBasicTable,
	EuiConfirmModal,
	EuiSpacer,
	formatDate,
} from "@equipmentshare/ds2";
import styled from "styled-components";

import { ExtendedUserGrant, ListIdentity } from "../../types/identity";
import {
	useRemoveUserGrantMutation,
	useSetDefaultUserGrantMutation,
} from "../../api/mutations/userGrants";
import { useToasts } from "../../utilities/toastProvider";
import { useIdentityByIdentityId } from "../../api/queries/useIdentityByIdentityId";

const BoldedSpan = styled.span`
	font-weight: bold;
`;

export type GrantsTableProps = {
	identity?: ListIdentity;
};

export default function GrantsTable({ identity }: GrantsTableProps) {
	let removeGrantModal;
	const auth0 = useAuth0();
	const toasts = useToasts();
	const queryClient = useQueryClient();
	const removeUserGrantMutation = useRemoveUserGrantMutation();
	const setDefaultUserGrantMutation = useSetDefaultUserGrantMutation();
	const { data, isFetching, isError } = useIdentityByIdentityId(
		auth0,
		identity?.identity_id ?? ""
	);
	const userGrants: ExtendedUserGrant[] = data?.user_grants ?? [];
	const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
	const [selectedGrant, setSelectedGrant] = useState<
		ExtendedUserGrant | undefined
	>();

	const removeUserGrant = () => {
		closeRemoveModal();
		removeUserGrantMutation.mutate(
			{
				identityId: identity?.identity_id ?? selectedGrant?.identity_id,
				userId: selectedGrant?.user_id?.toString(),
				auth0,
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(
						`get:useIdentityByIdentityId:${selectedGrant?.identity_id}`
					);
					toasts.showToast({
						id: new Date().getTime().toString(),
						color: "success",
						title: `Grant removed`,
						text: (
							<p>
								{identity?.email} : {selectedGrant?.user_email}
							</p>
						),
					});
					setSelectedGrant(undefined);
				},
				onError: () => {
					toasts.showToast({
						id: new Date().getTime().toString(),
						color: "danger",
						title: `Failed to remove grant`,
						text: (
							<p>
								{identity?.email} : {selectedGrant?.user_email}
							</p>
						),
					});
				},
			}
		);
	};

	const setDefaultUserGrant = (grant: ExtendedUserGrant) => {
		setDefaultUserGrantMutation.mutate(
			{
				userId: grant.user_id.toString(),
				identityId: grant.identity_id,
				auth0,
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(
						`get:useIdentityByIdentityId:${grant.identity_id}`
					);
					toasts.showToast({
						id: new Date().getTime().toString(),
						color: "success",
						title: `Updated default Grant`,
						text: (
							<p>
								{identity?.email} : {grant.user_email}
							</p>
						),
					});
				},
				onError: () => {
					toasts.showToast({
						id: new Date().getTime().toString(),
						color: "danger",
						title: `Failed to set default Grant`,
						text: (
							<p>
								{identity?.email} : {grant.user_email}
							</p>
						),
					});
				},
			}
		);
	};

	const closeRemoveModal = () => {
		setSelectedGrant(undefined);
		setIsRemoveModalVisible(false);
	};
	const showRemoveModal = (grant: ExtendedUserGrant) => {
		setSelectedGrant(grant);
		setIsRemoveModalVisible(true);
	};

	useEffect(() => {
		if (isError) {
			toasts.showToast({
				id: new Date().getTime().toString(),
				color: "danger",
				title: `Failed to fetch Grants for: ${identity?.email ?? "unknown"}`,
			});
		}
	}, [isError]); // eslint-disable-line react-hooks/exhaustive-deps

	if (isRemoveModalVisible) {
		removeGrantModal = (
			<EuiConfirmModal
				title="Remove Grant"
				onCancel={closeRemoveModal}
				onConfirm={removeUserGrant}
				cancelButtonText="No"
				confirmButtonText="Yes"
				buttonColor="danger"
				defaultFocusedButton="confirm"
			>
				<div>
					Are you sure you want to remove the grant:
					<EuiSpacer size="xs" />
					<BoldedSpan>
						{selectedGrant?.user_email}({selectedGrant?.user_id})
					</BoldedSpan>
					<EuiSpacer />
					from the identity:
					<EuiSpacer size="xs" />
					<BoldedSpan>{identity?.email}</BoldedSpan>
				</div>
			</EuiConfirmModal>
		);
	}

	let actions;
	actions = [
		{
			name: "Current Default",
			description: "This is the current default Grant",
			icon: "starFilled",
			type: "icon",
			// color: "danger",
			onClick: (grant: ExtendedUserGrant) => {
				/* noop */
			},
			available: (grant: ExtendedUserGrant) =>
				grant.user_id === data?.default_user_grant?.user_id,
		},
		{
			name: "Set Default",
			description: "Set this Grant as the default",
			icon: "starEmpty",
			type: "icon",
			// color: "ghost",
			onClick: (grant: ExtendedUserGrant) => setDefaultUserGrant(grant),
			available: (grant: ExtendedUserGrant) =>
				!(grant.user_id === data?.default_user_grant?.user_id),
		},
		{
			name: "Remove Grant",
			description: "Remove the Grant between this User and the Identity",
			icon: "trash",
			type: "icon",
			color: "danger",
			onClick: (grant: ExtendedUserGrant) => showRemoveModal(grant),
			available: (grant: ExtendedUserGrant) =>
				!(grant.user_id === data?.default_user_grant?.user_id),
		},
		{
			name: "Remove Disabled",
			description:
				"You must select a new default Grant before you can remove this one.",
			icon: "alert",
			type: "icon",
			color: "warning",
			disabled: true,
			onClick: (grant: ExtendedUserGrant) => {
				/* noop */
			},
			available: (grant: ExtendedUserGrant) =>
				grant.user_id === data?.default_user_grant?.user_id,
		},
	];

	const columns = [
		{
			field: "user_id",
			name: "User ID",
		},
		{
			field: "user_name",
			name: "Name",
		},
		{
			field: "user_email",
			name: "Email",
		},
		{
			field: "company_name",
			name: "Company",
		},
		{
			field: "start",
			name: "Start Date",
			render: (date: string) => formatDate(date, "shortDate"),
		},
		{
			field: "end",
			name: "End Date",
			render: (date: string) => formatDate(date, "shortDate"),
		},
		{
			field: "actions",
			name: "Actions",
			actions,
		},
	];

	const tableMessage = isFetching
		? "Loading..."
		: !isFetching && isError
		? "Error fetching Grants"
		: "No Grants Found";

	return (
		<>
			<EuiBasicTable
				tableCaption="Grants"
				items={userGrants}
				columns={columns}
				loading={isFetching || removeUserGrantMutation.isLoading}
				noItemsMessage={tableMessage}
				hasActions
			/>
			{removeGrantModal}
		</>
	);
}
