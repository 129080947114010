import { EuiDescribedFormGroup } from "@elastic/eui";
import {
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiButton,
	EuiSpacer,
	EuiFlexGroup,
	EuiFlexItem,
	EuiTitle,
} from "@equipmentshare/ds2";
import { Identity } from "../../types/identity";

type IdentityEditFormProps = {
	identity?: Identity;
};

export default function IdentityEditForm({ identity }: IdentityEditFormProps) {
	return (
		<EuiForm component="form">
			<EuiDescribedFormGroup
				title={
					<EuiTitle>
						<h3>Identity Details</h3>
					</EuiTitle>
				}
				description={<p>Edit the details of this Identity</p>}
			>
				<EuiFormRow label="Identity ID">
					<EuiFieldText
						disabled
						name="identityId"
						aria-label="Identity ID"
						value={identity?.identity_id}
					/>
				</EuiFormRow>

				<EuiFormRow label="Email">
					<EuiFieldText
						disabled
						name="email"
						aria-label="Email"
						value={identity?.email}
					/>
				</EuiFormRow>

				<EuiSpacer />

				<EuiFlexGroup justifyContent="flexStart">
					<EuiFlexItem grow={false}>
						<EuiButton type="submit" disabled>
							Save
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiDescribedFormGroup>
		</EuiForm>
	);
}
