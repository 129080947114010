import React, { useState } from "react";
import {
	EuiInMemoryTable,
	SortDirection,
	EuiScreenReaderOnly,
	EuiButtonIcon,
	EuiFlexGroup,
	EuiFlexItem,
	RIGHT_ALIGNMENT,
} from "@equipmentshare/ds2";
import { ListIdentity } from "../../types/identity";
import UserGrantQuickAdd from "../userGrantQuickAdd/userGrantQuickAdd";
import GrantsTable from "../grantsTable/grantsTable";

export type IdentitiesTablePropTypes = {
	identities: ListIdentity[] | undefined;
};

export default function IdentitiesTable({
	identities,
}: IdentitiesTablePropTypes) {
	const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<any>({});

	const toggleDetails = (item: ListIdentity) => {
		const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
		if (itemIdToExpandedRowMapValues[item.identity_id]) {
			delete itemIdToExpandedRowMapValues[item.identity_id];
		} else {
			itemIdToExpandedRowMapValues[item.identity_id] = (
				<EuiFlexGroup direction="column">
					<EuiFlexItem>
						<UserGrantQuickAdd identity={item} />
					</EuiFlexItem>
					<EuiFlexItem>
						<GrantsTable identity={item} />
					</EuiFlexItem>
				</EuiFlexGroup>
			);
		}
		setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
	};

	let actions;
	actions = [
		{
			name: "Open",
			description: "Open this identity in a new tab",
			type: "icon",
			icon: "symlink",
			onClick: (item: ListIdentity) => {
				const suffix = `/identities/${item.identity_id}`;
				const newTab = window.open(window.location.origin + suffix);
				newTab?.focus();
			},
		},
		{
			align: RIGHT_ALIGNMENT,
			width: "40px",
			isExpander: true,
			name: (
				<EuiScreenReaderOnly>
					<span>Expand rows</span>
				</EuiScreenReaderOnly>
			),
			render: (item: ListIdentity) => (
				<EuiButtonIcon
					color="primary"
					onClick={() => toggleDetails(item)}
					aria-label={
						itemIdToExpandedRowMap[item.identity_id] ? "Collapse" : "Expand"
					}
					iconType={
						itemIdToExpandedRowMap[item.identity_id] ? "arrowUp" : "arrowDown"
					}
				/>
			),
		},
	];

	const columns = [
		{
			field: "identity_id",
			name: "Identity ID",
			sortable: true,
		},
		{
			field: "email",
			name: "Email",
			sortable: true,
			truncateText: true,
		},
		{
			field: "actions",
			name: "Actions",
			actions,
		},
	];

	const sorting = {
		sort: {
			field: "email",
			direction: SortDirection.ASC,
		},
	};

	return (
		<>
			<div>
				<EuiInMemoryTable
					tableCaption="Identities"
					items={identities ?? []}
					columns={columns}
					sorting={sorting}
					tableLayout="auto"
					itemId="identity_id"
					isExpandable={true}
					itemIdToExpandedRowMap={itemIdToExpandedRowMap}
					hasActions
					pagination
				/>
			</div>
		</>
	);
}
