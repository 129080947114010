import { useState, useMemo, useEffect } from "react";
import { useQuery } from "react-query";
import {
	EuiComboBox,
	EuiComboBoxOptionOption,
	EuiIcon,
} from "@equipmentshare/ds2";
import { useAuth0 } from "@equipmentshare/auth0-react";

import { fetchUsers } from "../../api/queries/useUsersQuery";
import { ESAPIUser } from "../../types/user";
import debounce from "../../utilities/debounce";
import { ExtendedUserGrant, ListIdentity } from "../../types/identity";
import { useIdentityByIdentityId } from "../../api/queries/useIdentityByIdentityId";
import { useToasts } from "../../utilities/toastProvider";

export type UserSelectorProps = {
	identity: ListIdentity;
	onSelect: (option: EuiComboBoxOptionOption) => void;
};

export default function UserSelector({
	onSelect,
	identity,
}: UserSelectorProps) {
	const auth0 = useAuth0();
	const toasts = useToasts();
	const [query, setQuery] = useState("");
	const [selectedOption, setSelectedOption] = useState<any>();
	const apiRoot = process.env.REACT_APP_API_URL ?? "";
	const { data: fullIdentity, isError } = useIdentityByIdentityId(
		auth0,
		identity.identity_id
	);
	const alreadyGrantedUserIds = fullIdentity?.user_grants?.map(
		(grant: ExtendedUserGrant) => grant.user_id
	);

	const { data: usersData, isFetching } = useQuery(
		["users", query],
		() => fetchUsers(auth0, apiRoot, query),
		{
			enabled: Boolean(query),
			refetchOnWindowFocus: false,
		}
	);

	const options = usersData?.reduce(
		(
			opts: EuiComboBoxOptionOption[],
			user: ESAPIUser
		): EuiComboBoxOptionOption[] => {
			const userIsAlreadyGranted = alreadyGrantedUserIds?.includes(
				user.user_id
			);

			if (!userIsAlreadyGranted) {
				opts.push({
					label: `${user.email_address} | user_id:${user.user_id} | company_id:${user.company.company_id}`,
					key: user.user_id.toString(),
					value: user.email_address,
				});
			}

			return opts;
		},
		[]
	);

	const onChange = (options: EuiComboBoxOptionOption[]) => {
		onSelect(options[0]);
		setSelectedOption(options[0]);
	};

	const onSearch = (searchString: string) => {
		if (searchString.length > 2) {
			setQuery(searchString);
		}
	};

	const debouncedOnSearch = useMemo(() => debounce(onSearch, 600), []);

	useEffect(() => {
		if (isError) {
			toasts.showToast({
				id: new Date().getTime().toString(),
				color: "danger",
				title: `Failed to fetch Grants for: ${identity?.email ?? "unknown"}`,
			});
		}
	}, [isError]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<EuiComboBox
				async
				append={<EuiIcon type="search" />}
				isClearable
				isLoading={isFetching}
				onChange={onChange}
				onSearchChange={debouncedOnSearch}
				options={options ?? []}
				placeholder="Add a Grant by searching for Users"
				selectedOptions={selectedOption ? [selectedOption] : []}
				singleSelection={{ asPlainText: true }}
			/>
		</>
	);
}
