import React from "react";
import { useHistory } from "react-router-dom";
import { useIdentity } from "@equipmentshare/auth0-react";
import {
	EuiText,
	EuiSpacer,
	EuiFlexGroup,
	EuiFlexItem,
	EuiCard,
	EuiIcon,
} from "@equipmentshare/ds2";

export default function HomeView() {
	const history = useHistory();
	const identity = useIdentity();

	return (
		<>
			<EuiText>
				<h2>Welcome, {identity.fullName}.</h2>
				<div>Email: {identity.email}</div>
				<div>
					Identity ID: {identity.identityId ?? <strong>Legacy User</strong>}
				</div>
			</EuiText>

			<EuiSpacer />

			<EuiFlexGroup gutterSize="l">
				<EuiFlexItem>
					<EuiCard
						icon={<EuiIcon size="xxl" type="usersRolesApp" />}
						title="Mange Identities"
						description="Mange grants for other Identities."
						onClick={() => {
							history.push("/identities");
						}}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</>
	);
}
