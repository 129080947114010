import { useIdentity } from "@equipmentshare/auth0-react";
import { HeaperOptions, useHeaper } from "@equipmentshare/heaper";

const HeaperContainer = () => {
    const { accessToken, idToken } = useIdentity();

    if (!accessToken || !idToken) return null;

    return <HeaperComponent accessToken={accessToken} idToken={idToken} />;

};

type HeaperComponentProps = {
    accessToken: string,
    idToken: string,
};

const HeaperComponent = ({ accessToken, idToken }: HeaperComponentProps) => {
    const options: HeaperOptions = {
        accessToken,
        idToken,
        extendEventProps: () => ({ app_version: process.env.REACT_APP_VERSION }),
        intercom: false,
    };

    useHeaper(
        "Identity Management",
        process.env.REACT_APP_PROMOTION_LEVEL === "master" ? "Production" : process.env.REACT_APP_PROMOTION_LEVEL === "local" ? "Sandbox" : "Staging",
        options
    );

    return null;
};

export default HeaperContainer;
