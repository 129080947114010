import { useAuth0 } from "@equipmentshare/auth0-react";
import { UniversalHeaderConsumable } from "@equipmentshare/universal-header";

export default function Header() {
	let auth0 = useAuth0();

	return (
		<UniversalHeaderConsumable
			environmentConfiguration={{
				appId: 1,
				auth0,
				environment:
					process.env.REACT_APP_PROMOTION_LEVEL === "master"
						? "production"
						: "staging",
			}}
		/>
	);
}
