import { useQuery, UseQueryResult } from "react-query";
import { Auth0ContextInterface } from "@equipmentshare/auth0-react";
import { ListIdentity } from "../../types/identity";
import makeAuthHeader from "../makeAuthHeader";

export async function fetchIdentitiesByQuery(
	auth0: Auth0ContextInterface,
	query: string | undefined
) {
	if (!query) {
		return undefined;
	}

	const headers = await makeAuthHeader(auth0);
	const identityResponse = await fetch(
		`${process.env.REACT_APP_API_URL}/admin/identities/list?email_starts_with=${query}&paging_limit=10`,
		{ headers }
	);

	if (!identityResponse.ok) {
		throw new Error(identityResponse.statusText);
	}

	const responseJson = await identityResponse.json();

	return responseJson.identities as ListIdentity[];
}

export const useIdentitiesSearchQuery = (
	auth0: Auth0ContextInterface,
	query: string
): UseQueryResult<ListIdentity[] | undefined> => {
	return useQuery<ListIdentity[] | undefined>(
		`get:useIdentitiesSearchQuery:${query}`,
		() => fetchIdentitiesByQuery(auth0, query),
		{ refetchOnWindowFocus: false }
	);
};
