import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { EuiPageTemplate } from "@equipmentshare/ds2";
import Header from "./layout/header";
import SideNav from "./layout/sidenav";
import HomeView from "./views/home";
import IdentityView from "./views/identity";
import IdentitiesView from "./views/identities";
import Heaper from "./components/heaper/heaper";

function App() {
	return (
		<div className="App">
			<Heaper />
			<Header />
			<EuiPageTemplate pageSideBar={<SideNav />} restrictWidth="90%">
				<Router>
					<Switch>
						<Route exact path="/">
							<HomeView />
						</Route>
						<Route exact path="/identities">
							<IdentitiesView />
						</Route>
						<Route exact path="/identities/:identityId">
							<IdentityView />
						</Route>
					</Switch>
				</Router>
			</EuiPageTemplate>
		</div>
	);
}

export default App;
