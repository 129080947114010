import { EuiTitle } from "@elastic/eui";
import { EuiSpacer } from "@equipmentshare/ds2";
import IdentitiesTableSearch from "../components/identitiesTableSearch/identitiesTableSearch";

export default function IdentitiesView() {
	return (
		<>
			<EuiTitle size="l">
				<h2>Manage Identities</h2>
			</EuiTitle>

			<EuiSpacer size="l" />

			<EuiTitle size="xs">
				<h3>Search Identities by Email</h3>
			</EuiTitle>

			<EuiSpacer size="s" />

			<IdentitiesTableSearch />
		</>
	);
}
