import { useEffect, useState } from "react";
import { useAuth0 } from "@equipmentshare/auth0-react";
import {
	EuiFieldSearch,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButton,
	EuiSpacer,
} from "@equipmentshare/ds2";

import IdentitiesTable from "../identitiesTable/identitiesTable";
import { useIdentitiesSearchQuery } from "../../api/queries/useIdentitiesSearchQuery";
import { useToasts } from "../../utilities/toastProvider";

export default function IdentitiesTableSearch() {
	const auth0 = useAuth0();
	const [query, setQuery] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const toasts = useToasts();

	const {
		data: identitiesData,
		isFetching,
		isError,
	} = useIdentitiesSearchQuery(auth0, query);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const onSearch = () => {
		setQuery(searchValue);
	};

	useEffect(() => {
		if (isError) {
			toasts.showToast({
				id: new Date().getTime().toString(),
				color: "danger",
				title: "Failed to fetch Identities",
			});
		}
	}, [isError]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<EuiFlexGroup>
				<EuiFlexItem>
					<EuiFieldSearch
						placeholder="ex. jane.smith@equipmentshare.com"
						value={searchValue}
						fullWidth
						isClearable={true}
						disabled={isFetching}
						onChange={onChange}
						onSearch={onSearch}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButton onClick={onSearch} isLoading={isFetching}>
						Search
					</EuiButton>
				</EuiFlexItem>
			</EuiFlexGroup>

			<EuiSpacer size="l" />

			<IdentitiesTable identities={identitiesData} />
		</>
	);
}
